<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <!-- Brand logo-->
    <b-link class="brand-logo mb-2">
      <h2 class="brand-text text-primary ml-1">
        <b-img fluid :src="logoImg" alt="Logo da Agenda"></b-img>
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2>Você não tem permissão para acessar esta página</h2>
        <p class="mb-2">Contate seu administrador</p>
        <p class="mb-1">Clique no botão abaixo para voltar a página inicial</p>
        <b-button variant="primary" class="mb-1 btn-sm-block" :to="{ name: 'adm' }">Tela Inicial</b-button>
        <b-img v-bind="imgerror" fluid :src="imgUrl" alt="ERROR-401 Imagem" />
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable global-require */
  import { BLink, BImg, BButton } from 'bootstrap-vue'
  import { getHomeRouteForLoggedInUser } from '@/auth/utils'

  export default {
    components: {
      BLink,
      BImg,
      BButton,
    },
    data() {
      return {
        downImg: require('@/assets/images/pages/Unauthorized.png'),
        logoImg: require('@/assets/images/pages/logoAgenda.png'),
        imgerror: {
          center: true,
          width: 500,
          height: 300,
        },
      }
    },
    computed: {
      imgUrl() {
        this.downImg = require('@/assets/images/pages/Unauthorized.png')
        return this.downImg
      },
      logoAgenda() {
        this.logoImg = require('@/assets/images/pages/logoAgenda.png')
        return this.logoImg
      },
    },
    methods: {
      loginRoute() {
        const user = this.$jwtDecode(localStorage.getItem('userData')).userData
        return getHomeRouteForLoggedInUser(user ? user.role : null)
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
